<template>
  <body-layout :style="backCss">
    <div class="actions flex" slot="actions">
      <a-button type="danger" class="mr-3" @click="batchDel" v-if="selectedRowKeys.length" icon="delete">删除</a-button>
      <!--  <a-button v-has="'ProductDetailPop:add'" type="primary" @click="openAddForm">新建</a-button> -->
    </div>
    <div class="table-page-search-wrapper" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input style="width:300px" v-model="queryParam.query" placeholder="请输入人员昵称或者标题、内容" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div style="padding-top:10px;padding-bottom:20px;">
      <a-radio-group v-model="radioValue">
        <a-radio value="1">全部</a-radio>
        <a-radio value="2">已解决</a-radio>
        <a-radio value="3">未解决</a-radio>
      </a-radio-group>
    </div>
    <a-table
      ref="table"
      id="out-table"
      size="middle"
      :rowKey="record => record.questionAnswer.id"
      :columns="columns.filter(item=>checkPermission()?true:item.dataIndex=='action'?false:true)"
      :customRow="clickRow"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 446px)' }"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
      @change="handleTableChange"
    >
      <span slot="sort" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="content" slot-scope="text, record">
        <div v-if="!record.questionAnswer.title" class="list-content" v-html="record.questionAnswer.content"></div>
        <div v-else>{{ record.questionAnswer.title }}</div>
      </span>
      <span slot="gmtCreate" slot-scope="text, record">
        {{ record.questionAnswer.gmtCreate }}
      </span>
      <span slot="finish" slot-scope="text, record">
        <span v-if="record.questionAnswer.finish">
          <a-icon style="color:green;margin-right:5px" type="check-circle" theme="filled" />已解决
        </span>
        <span v-else> <a-icon type="close-circle" style="color:red;margin-right:5px" theme="filled" />未解决 </span>
      </span>
      <span slot="finishTime" slot-scope="text, record">
        {{ record.questionAnswer.finishTime }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click.stop="finish(record.questionAnswer.id)">标记为“已解决”</a>
      </span>
      <span slot="user" slot-scope="text, record">
        <span>
          <img
            v-if="record.user.avatar"
            :src="record.user.avatar"
            style="width:35px;height:35px;border-radius: 50%;"
            alt=""
          />
          <img v-else src="./../../assets/tx.png" style="width:40px;height:40px;border-radius: 50%;" alt="" />
          <span style="margin-left:15px">{{ record.user.realname }}</span>
        </span>
      </span>
      <span slot="files" slot-scope="text, record">
        <div :href="i" v-for="(i, index) in record.files" :key="index">
          <span @click.stop="play(i)" class="file-span">{{ i.split('/')[i.split('/').length - 1] }}</span>
          <a :href="i+'?response-content-type=application/octet-stream'" target="downloadFile" style="margin-left:3px;">
            <a-icon type="download" />
          </a>
        </div>
      </span>
    </a-table>
    <playvideo-modal ref="videoModal" />
    <helpAndMsg-modal
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      :rowId="rowId"
      @closed="drawerClosed"
    ></helpAndMsg-modal>
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import helpAndMsgModal from './modules/helpAndMsg/helpAndMsg-modal.vue'
import { simpleDebounce } from '@/utils/util'
import { checkPermission } from '@/utils/hasPermission'
import playvideoModal from '@/components/playvideo-modal'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
export default {
  mixins: [JeecgListMixin],
  components: {
    helpAndMsgModal,
    playvideoModal
  },
  data() {
    return {
      positionList: [],
      rowId: '',
      radioValue: '1',
      curRow: [],
      btnLoading: false,
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0
      },
      translateUsers: [],
      userIds: [],
      monthFormat: 'YYYY-MM',
      month: '',
      isEdit: false,
      isAdmin: false,
      searchUsers: [],
      userInfo: {
        roles: []
      },
      url: {
        list: '/questionAnswer/page',
        delete: '/fonts/delete',
        deleteBatch: '/productionOnline'
      },
      color: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: 50
        },
        {
          title: '标题',
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
          width: 300
        },
        {
          title: '附件',
          dataIndex: 'files',
          scopedSlots: { customRender: 'files' },
          width: 180
        },
        {
          title: '反馈人员',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
          width: 180
        },
        {
          title: '反馈时间',
          dataIndex: 'gmtCreate',
          scopedSlots: { customRender: 'gmtCreate' },
          width: 180
        },
        {
          title: '状态',
          dataIndex: 'finish',
          scopedSlots: { customRender: 'finish' },
          width: 120
        },
        {
          title: '解决时间',
          dataIndex: 'finishTime',
          scopedSlots: { customRender: 'finishTime' },
          width: 180
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 150
        }
      ]
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  watch: {
    radioValue() {
      if (this.radioValue == '3') {
        this.queryParam.finish = false
      } else if (this.radioValue == '2') {
        this.queryParam.finish = true
      } else {
        delete this.queryParam.finish
      }
      this.loadData('1')
    }
  },
  methods: {
    checkPermission() {
      return checkPermission('helpAndMsg:action')
    },
    play(url) {
      if (this.$refs.videoModal) this.$refs.videoModal.open(url)
    },
    openDetail: simpleDebounce(async function(row) {
      this.openDetailSim(row)
    }, 500),
    async openDetailSim(row) {
      this.isAdd = true
      if (this.mytasksDetialPath) {
        this.$router.push({
          path: `${this.mytasksDetialPath}/${row.taskId}/${row.productionId}/${row.chapterId}?${
            this.fromProduction ? 'fromProduction=1' : ''
          }&record=${encodeURIComponent(
            JSON.stringify({ platformId: row.platformId, productionName: row.productionName })
          )}`
        })
      } else if (this.detailPath) {
        this.$router.push({ path: `${this.detailPath}/${row.id}` })
      } else {
        const res = await getAction('/questionAnswer/detail?id=' + row.questionAnswer.id)
        if (res.code == 200) {
          this.curRow = res.data
          this.rowId = row.questionAnswer.id
        }
        /* this.curRow = JSON.parse(JSON.stringify(row)) //防止在drawer中修改内容会影响列表 */
        console.log({ 'openDetail this.curRow': this.curRow })
        if (this.curRow.cardList) {
          this.curRow.cardList.forEach(item => {
            if (item.dataList == null) {
              item.dataList = [{ valueRequired: 'NO' }]
            }
          })
        }
        this.drawerShow = true
        this.isNewForm = true
      }
    },
    getShowHome(time) {
      let text = '未展示'
      if (time) {
        //发布时间是否超过当前时间
        if (new Date(time).getTime() < new Date().getTime()) {
          text = '首页展示中'
        }
      }
      return text
    },
    async finish(id) {
      const res = await postAction('/questionAnswer/finish?id=' + id)
      if (res.code == 200) {
        this.$message.success('已解决')
        this.radioValue = '1'
        this.loadData('1')
      } else {
        this.$message.error(res.msg)
      }
    },
    batchDel: function() {
      let data = []
      let that = this
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)).questionAnswer.id)
      })
      data = data.join(',')
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/questionAnswer/delete?id=' + data)
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.loadData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    userSearch(value) {
      if (value) {
        this.searchUsers = this.translateUsers.filter(item => item.userName.indexOf(value) > -1)
      } else {
        this.searchUsers = []
      }
    },
    searchQueryForm() {
      if (this.month && this.month.format) {
        this.queryParam.month = this.month.format(this.monthFormat)
      } else {
        this.queryParam.month = ''
      }
      this.queryParam.userIds = this.userIds
    },
    async stop(record) {
      const res = await putAction('productionOnline/unpublish/' + record.productionOnline.id)
      if (res.code == 200) {
        this.$message.success('已停用')
        this.loadData()
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async send(record) {
      const res = await putAction('productionOnline/publish', { id: record.productionOnline.id })
      if (res.code == 200) {
        this.$message.success('发布成功')
        this.loadData()
      } else {
        this.$message.error(res.msg || res.message)
      }
    }
  },
  created() {
    this.$bus.$on('openHelpMsg', record => {
      this.openDetail(record)
    })
  }
}
</script>

<style lang="less" scoped>
.red {
  color: red;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
/deep/ .list-content {
  p {
    margin-bottom: 0 !important;
  }
}
.file-span {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: var(--theme-color);
  }
}
</style>
