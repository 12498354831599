var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    { style: _vm.backCss },
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "table-page-search-wrapper",
          attrs: { slot: "search" },
          slot: "search",
        },
        [
          _c(
            "a-form",
            {
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    [
                      _c("a-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入人员昵称或者标题、内容" },
                        model: {
                          value: _vm.queryParam.query,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "query", $$v)
                          },
                          expression: "queryParam.query",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "padding-top": "10px", "padding-bottom": "20px" } },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.radioValue,
                callback: function ($$v) {
                  _vm.radioValue = $$v
                },
                expression: "radioValue",
              },
            },
            [
              _c("a-radio", { attrs: { value: "1" } }, [_vm._v("全部")]),
              _c("a-radio", { attrs: { value: "2" } }, [_vm._v("已解决")]),
              _c("a-radio", { attrs: { value: "3" } }, [_vm._v("未解决")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        ref: "table",
        attrs: {
          id: "out-table",
          size: "middle",
          rowKey: (record) => record.questionAnswer.id,
          columns: _vm.columns.filter((item) =>
            _vm.checkPermission()
              ? true
              : item.dataIndex == "action"
              ? false
              : true
          ),
          customRow: _vm.clickRow,
          dataSource: _vm.dataSource,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          scroll: { x: "max-content", y: "calc(100vh - 446px)" },
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 45,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "sort",
            fn: function (text, record, index) {
              return _c("span", {}, [
                _vm._v("\n      " + _vm._s(index + 1) + "\n    "),
              ])
            },
          },
          {
            key: "content",
            fn: function (text, record) {
              return _c("span", {}, [
                !record.questionAnswer.title
                  ? _c("div", {
                      staticClass: "list-content",
                      domProps: {
                        innerHTML: _vm._s(record.questionAnswer.content),
                      },
                    })
                  : _c("div", [_vm._v(_vm._s(record.questionAnswer.title))]),
              ])
            },
          },
          {
            key: "gmtCreate",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  "\n      " +
                    _vm._s(record.questionAnswer.gmtCreate) +
                    "\n    "
                ),
              ])
            },
          },
          {
            key: "finish",
            fn: function (text, record) {
              return _c("span", {}, [
                record.questionAnswer.finish
                  ? _c(
                      "span",
                      [
                        _c("a-icon", {
                          staticStyle: {
                            color: "green",
                            "margin-right": "5px",
                          },
                          attrs: { type: "check-circle", theme: "filled" },
                        }),
                        _vm._v("已解决\n      "),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c("a-icon", {
                          staticStyle: { color: "red", "margin-right": "5px" },
                          attrs: { type: "close-circle", theme: "filled" },
                        }),
                        _vm._v("未解决 "),
                      ],
                      1
                    ),
              ])
            },
          },
          {
            key: "finishTime",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  "\n      " +
                    _vm._s(record.questionAnswer.finishTime) +
                    "\n    "
                ),
              ])
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c("span", {}, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.finish(record.questionAnswer.id)
                      },
                    },
                  },
                  [_vm._v("标记为“已解决”")]
                ),
              ])
            },
          },
          {
            key: "user",
            fn: function (text, record) {
              return _c("span", {}, [
                _c("span", [
                  record.user.avatar
                    ? _c("img", {
                        staticStyle: {
                          width: "35px",
                          height: "35px",
                          "border-radius": "50%",
                        },
                        attrs: { src: record.user.avatar, alt: "" },
                      })
                    : _c("img", {
                        staticStyle: {
                          width: "40px",
                          height: "40px",
                          "border-radius": "50%",
                        },
                        attrs: {
                          src: require("./../../assets/tx.png"),
                          alt: "",
                        },
                      }),
                  _c("span", { staticStyle: { "margin-left": "15px" } }, [
                    _vm._v(_vm._s(record.user.realname)),
                  ]),
                ]),
              ])
            },
          },
          {
            key: "files",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                _vm._l(record.files, function (i, index) {
                  return _c("div", { key: index, attrs: { href: i } }, [
                    _c(
                      "span",
                      {
                        staticClass: "file-span",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.play(i)
                          },
                        },
                      },
                      [_vm._v(_vm._s(i.split("/")[i.split("/").length - 1]))]
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { "margin-left": "3px" },
                        attrs: {
                          href:
                            i +
                            "?response-content-type=application/octet-stream",
                          target: "downloadFile",
                        },
                      },
                      [_c("a-icon", { attrs: { type: "download" } })],
                      1
                    ),
                  ])
                }),
                0
              )
            },
          },
        ]),
      }),
      _c("playvideo-modal", { ref: "videoModal" }),
      _c("helpAndMsg-modal", {
        attrs: {
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
          rowId: _vm.rowId,
        },
        on: { closed: _vm.drawerClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }